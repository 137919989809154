<template>
    <div class="container h-100">
    </div>
 </template>
 
 <script>
fbq('track', 'ViewContent');


 var rldata = {
   user: null,
   new_user: {
     full_name: '',
     email: '',
     password: '',
     terms_check: true
   },
   new_child: {
     first_name: '',
     initial: '',
     password: '',
     terms_check: true
   },
 
   child_parent_switcher: "child",
 
   showPassword: false,
 }
 
 import router from "@/router"
 import axios from "axios"
 
 export default {
   name: "start_now",
   data () {
     return {
       rldata
     }
   },
   methods: {   
    child_signup_form: function () {
            let cute_nicknames = [
                'angel',
                'diamond',
                'baby',
                'fleur',
                'fluffy',
                'bambi',
                'cesar',
                'princess'
            ];

            let cute_beeings = [
                'elephant',
                'bunny',
                'hedgehog',
                'fox',
                'penguin',
                'quokka',
                'giraffe',
                'lemur',
                'seal'
            ];

            let randIndex__cute_nicknames = Math.floor(Math.random() * 8);
            let randIndex__cute_beeings = Math.floor(Math.random() * 9);

            let new_username = cute_nicknames[randIndex__cute_nicknames] + '_' + cute_beeings[randIndex__cute_beeings];
            let new_password = Math.random().toString(36).substr(2, 8);

            new_username = new_username.replace(/\s+/g, '');
            new_username = new_username.replace(/\'+/g, '');
            new_username = new_username.replace(/-+/g, '');
            new_username = new_username.toLowerCase();
             
             
             let data = {
               first_name: new_username,
               initial: "",
               username: new_username,
               password: new_password,
               adult_connected: false
             };
            //  console.log(data);
             this.$root.add_new_child(data);
     },
   },
   mounted () {
    this.child_signup_form();
   }
 }
 </script>
 